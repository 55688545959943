import React from "react";
import "./styles.css";
import fretboard from "../../core/fretboard";
import { byKeyAndLabel } from "../../core/namedTunings";
import { scaleDirection } from "../../core/octaves";

import { connect } from "react-redux";
import * as actions from "../../store/actions/actions";
import { updateOptions } from "../../store/actions/actions";

class OptionsBar extends React.Component {
  constructor(props) {
    super(props);
    this.updateOptions = this.updateOptions.bind(this);
  }

  updateOptions(updates) {
    const newOptions = {
      ...this.props.options,
      ...updates
    };

    this.props.dispatch(updateOptions(newOptions));
  }

  render() {
    let startFretValues = [];
    for (let index = 0; index < this.props.options.endFret + 1; index++) {
      let label = index === 0 ? "Open strings" : `Fret ${index}`;
      startFretValues.push(<option key={`sf-${index}`} value={index}>{label}</option>);
    }

    let endFretValues = [];
    for (let index = this.props.options.startFret; index < fretboard.frets().length; index++) {
      let label = index === 0 ? "Open strings" : `Fret ${index}`;
      endFretValues.push(<option key={`sf-${index}`} value={index}>{label}</option>);
    }

    let tuningOptions = byKeyAndLabel.map((k) => {
      return <option key={k.key} value={k.key}>{k.label}</option>;
    });

    return (
      <div className="options-navbar">
        {/* attempts */}
        <div className="control">
          <label className="label"> attempts</label>
          <select
            id="opt-select-attempts"
            disabled={this.props.currentRun.inProgress}
            value={this.props.options.attempts}
            onChange={(e) => {
              this.updateOptions({ attempts: Number.parseInt(e.target.value) });
            }}>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
          </select>
        </div>

        {/* starting fret */}
        <div className="control">
          <label className="label">start</label>
          <select
            id="opt-select-start-fret"
            disabled={this.props.currentRun.inProgress}
            value={this.props.options.startFret}
            onChange={(e) => {
              this.updateOptions({ startFret: Number.parseInt(e.target.value) });
            }}>
            >
            {startFretValues}
          </select>
        </div>

        {/* end fret */}
        <div className="control">
          <label className="label">end</label>
          <select
            id="opt-select-end-fret"
            disabled={this.props.currentRun.inProgress}
            value={this.props.options.endFret}
            onChange={(e) => {
              this.updateOptions({ endFret: Number.parseInt(e.target.value) });
            }}>
            >
            {endFretValues}

          </select>
        </div>

        {/* sharps or flats */}
        <div className="control">
          <select
            id="opt-select-sharps-or-flats"
            disabled={this.props.currentRun.inProgress}
            value={this.props.options.scaleDirection}
            onChange={(e) => {
              this.updateOptions({ scaleDirection: e.target.value });
            }}>
            <option value={scaleDirection.ascending}>sharps</option>
            <option value={scaleDirection.descending}>flats</option>
            <option value={scaleDirection.both}>both</option>
          </select>
        </div>

        {/* guess timeout*/}
        <div className="control">
          <select
            id="opt-select-guess-timeout"
            disabled={this.props.currentRun.inProgress}
            value={this.props.options.guessTimeout}
            onChange={(e) => {
              this.updateOptions({ guessTimeout: Number.parseInt(e.target.value) });
            }}>
            >
            <option value={0}>No time limit</option>
            <option value={1000}>1 sec</option>
            <option value={2000}>2 sec</option>
            <option value={3000}>3 sec</option>
            <option value={4000}>4 sec</option>
            <option value={5000}>5 sec</option>
            <option value={6000}>6 sec</option>
            <option value={7000}>7 sec</option>
            <option value={8000}>8 sec</option>
            <option value={9000}>9 sec</option>
            <option value={10000}>10 sec</option>
          </select>
        </div>

        {/* tuning */}
        <div className="control">
          <select
            id="opt-select-tuning"
            disabled={this.props.currentRun.inProgress}
            value={this.props.options.tuning}
            onChange={(e) => {
              this.updateOptions({ tuning: e.target.value });
            }}>
            {tuningOptions}
          </select>
        </div>

        {/* start button */}
        <div className="control">
          <button
            onClick={() => this.props.startRun(this.props.options, this.props.currentRun)}
            disabled={this.props.currentRun.inProgress}
            className=""
          >Start
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startRun: (options, currentRun) => dispatch(actions.startRun(options, currentRun)),
    dispatch: dispatch
  };
};

const mapStateToProps = state => {
  return {
    options: state.options,
    currentRun: state.run
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsBar);
