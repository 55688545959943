const saveState = (key, val) => {
  try {
    const serializedState = JSON.stringify(val);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    console.log(err);
    // TODO: capture this and remove console.log
  }
};

const loadState = (key) => {
  try {
    const state = localStorage.getItem(key);
    if (state) {
      return JSON.parse(state);
    }
    return null;
  } catch (err) {
    // TODO: capture this and remove console.log
    console.log(err);
  }
};

export { saveState, loadState };
