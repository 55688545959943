import React from "react";
import "./styles.css";
import {chromaticScale} from "../../core/octaves";
import {connect} from "react-redux";
import CurrentAttemptIndicator from "./CurrentAttemptIndicator/CurrentAttemptIndicator";
import FinishRoundButton from "../FinishRoundButton/FinishRoundButton";
import * as actions from "../../store/actions/actions";

const UserNoteSelector = (props) => {
  const notes = chromaticScale.map(function (note, idx) {
    // we are cloning the chromaticScale note because
    // we may modify its label.
    let _note = {...note};
    if (note.isAccidental) {
      switch (props.currentRun.currentNote.scaleDirection) {
        case "Ascending":
          _note.label = _note.sharp;
          break;
        case "Descending":
          _note.label = _note.flat;
          break;
        default:
          break;
      }
    }

    return (
      <button
        className="note-button"
        disabled={!props.currentRun.userControlsEnabled}
        key={`n-${idx}`}
        onClick={(evt) => props.click(props.currentRun, _note)}
      >
        {_note.label}
      </button>
    );
  });

  const onFinishRound = (e) => {
    e.preventDefault();
    props.finishRound(props.currentRun);
  }

  return (
    <div className="ChromaticScale">
      <div className='attempt-indicator'>
        <CurrentAttemptIndicator/>
      </div>
      <div className='scale-notes'>{notes}</div>
      <div className='stop-button'>
        <FinishRoundButton click={onFinishRound}/>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentRun: state.run,
    options: state.options,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    click: (currentRun, guessedNote) => {
      dispatch(actions.userGuessed(currentRun, guessedNote));
    },
    finishRound: (currentRun) => {
      dispatch(actions.finalizeRound(currentRun));
    }

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserNoteSelector);
