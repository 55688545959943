import React from 'react'
import "./styles.css"
import { connect } from 'react-redux'

const UserMessage = (props) => {
  const ui = (
    <div className="user-message">
      <span> {props.currentRun.userMessage.text}</span>
    </div>
  )

  return (
    props.currentRun.inProgress ? ui : ''
  )
}

const mapStateToProps = (state) => {
  return {
    currentRun: state.run
  }
}

export default connect(mapStateToProps)(UserMessage);