import React from "react";
import "./app.scss";
import { connect } from 'react-redux';
import UserNoteSelector from "./UI/UserNoteSelector/UserNoteSelector";
import UserMessage from "./UI/UserMessage/UserMessage";
import Fretboard from "./UI/Fretboard/Fretboard";
import OptionsBar from "./UI/OptionsBar/OptionsBar";
import Version from "./UI/Version/Version";

class App extends React.Component {
  // should not re-render fretboard if state.currentNote has not changed
  render() {
    const userMessage = this.props.currentRound.userMessage
      ? <UserMessage />
      : ''

    const userNoteSelector = this.props.currentRound.inProgress
      ? <UserNoteSelector />
      : ''

    const optionsBar = this.props.currentRound.inProgress
      ? ''
      : <OptionsBar
        inProgress={this.props.currentRound.inProgress}
      />

    return (
      <>
        {userMessage}
        {userNoteSelector}
        {optionsBar}

        <Fretboard />
        <Version />
      </>
    )
  }
}

// const onFinishRound = (e) => {
//   e.preventDefault();
// }

const mapStateToProps = (state) => {
  return {
    currentRound: state.run,
    options: state.options
  }
}

export default connect(mapStateToProps)(App);
