import React from "react";
import ReactDOM from "react-dom";
import {createStore, compose, applyMiddleware} from "redux";
import reducer from './store/reducers';
import {Provider} from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './store/sagas/index';
import App from "./App";
import * as actions from './store/actions/actions';

const sagaMiddleware = createSagaMiddleware();
//TODO: this is not be loaded in production
let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
if (process.env.NODE_ENV === 'production' || !composeEnhancers) {
  composeEnhancers = compose
}

// const reduxToolsMiddleware = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const store = createStore(
  reducer,
  composeEnhancers(
    applyMiddleware(sagaMiddleware)
  )
)

sagaMiddleware.run(rootSaga);

// Set initial displayed notes from options in store.
try {
  // init app. This will setup the fretboard.
  store.dispatch(actions.initApp(store.getState().options))

  // fill the fretboard with some notes.
  store.dispatch(actions.setDisplayedNotesFromOptions(store.getState().options))
} catch (e) {
  // TODO: log this with sentry?
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App/>
    </Provider>
  </React.StrictMode>,
  rootElement
);
