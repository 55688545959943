import React from 'react';
import Fret from "../Fret/Fret";

const String = ({guitarString}) => {
  const frets = guitarString.notes.map((fretNote, idx) =>
    <Fret
      fretNote={fretNote}
      key={`fretNote-${idx}`}
    />)

  return (
    <div className="string">
      {frets}
    </div>
  )
}

export default String;