import fretboard from "./fretboard.js";
import { cycler } from "./utils";
import { last } from "lodash";
import { isSameFretNote } from "./utils";

/**
 * Returns an array with numbers representing the strings
 * in current fretboard. E.g for a bass it returns [1,2,3,4]
 * for bouzouki [1,2,3], for guitar [1,2,3,4,5,6];
 * @returns {number[]}
 */
export function allStrings() {
  const a = fretboard.strings().map((s) => s.id);
  return a;
}

// Returns a random number between the specified values.
// The returned value is no lower than (and may possibly equal) min,
// and is less than (and not equal) max.
function getRandomNumber(minFret, maxFret) {
  return Math.floor(Math.random() * (maxFret - minFret) + minFret);
}

// strArr: an array with strings to include.
// Eg. [1,2,3,4,5,6] would be all the strings for 6 strings guitar.
function getRandomGuitarString(strArr = allStrings()) {
  return getRandomNumber(1, strArr.length + 1);
}

/**
 * Returns an array of notes
 * @param {number} noOfRequestedNotes - how many notes to return
 * @param {number} startFret - the start fret
 * @param {number} endFret - the end fret
 * @param {number[]} stringsArr - Which strings to include
 * @param {string} scaleDirection - either Ascending, Descending or Mixed
 * @return {FretNote[]}
 */
function getRandomFretNotes(
  noOfRequestedNotes,
  startFret,
  endFret,
  stringsArr = allStrings(),
  scaleDirection = "Ascending"
) {
  let notes = [];
  // max number of notes that we can generate from given fret + string combo
  // e.g: 2 frets and 6 strings can produce 12 notes.
  // We should generate 12 unique notes and fill the rest with duplicates.
  const countOfMaxPossibleUniqueNotes = (endFret - startFret + 1) * stringsArr.length;
  let requireUniqueNotes = true;

  const directionCycler = cycler(["Ascending", "Descending"]);

  while (notes.length < noOfRequestedNotes) {
    let randomString = getRandomGuitarString(stringsArr);
    let randomFret = getRandomNumber(startFret, endFret + 1);

    // clone the fret note
    const fretNote = fretboard.fret(randomFret).string(randomString);
    let note = {
      ...fretNote,
    };

    // enhance the FretNote object with 'direction' and becomes a what?
    // maybe a FretNoteWithDirection?
    if (note.isAccidental) {
      if (scaleDirection === "Both") {
        note.scaleDirection = directionCycler();
      } else {
        note.scaleDirection = scaleDirection;
      }
    }

    // This is temp. Will be removed after we get ird of scaleDirection
    switch (note.scaleDirection) {
      case "Ascending":
        note.label = fretNote.sharp;
        break;
      case "Descending":
        note.label = fretNote.flat;
        break;
      default:
        break;
    }

    if (requireUniqueNotes) {
      if (!notes.find((n) => n.string === note.string && n.fret === note.fret)) {
        notes.push(note);
      }
    } else {
      // don't repeat notes in sequence
      if (!isSameFretNote(note, last(notes))) notes.push(note);
    }

    // we have added the maximum number of unique notes we can produce
    // from given fret + strings combo. Let's switch to non-unique mode
    // to fill up the rest.
    if (notes.length === countOfMaxPossibleUniqueNotes) requireUniqueNotes = false;
  }
  return notes;
}

export { getRandomFretNotes };
