import fretboard from "./fretboard";
import { states } from "./noteStates";

function setupActiveNotesForDisplay(startFret, endFret, scaleDirection) {
  let notes = [];
  for (let fret = startFret; fret <= endFret; fret++) {
    for (let string = 1; string <= fretboard.strings().length; string++) {
      // enhance a FretNote and make it a what?
      const fretNote = {
        ...fretboard.fret(fret).string(string),
        state: states.DISPLAY,
        scaleDirection: scaleDirection,
      };
      notes.push(fretNote);
    }
  }
  return notes;
}

export { setupActiveNotesForDisplay };
