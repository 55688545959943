import * as actionTypes from "../actions/actionTypes";
import { states } from "../../core/noteStates";
import FinishedRound from "../../core/finishedRound";
import { last } from "lodash";
import { setupActiveNotesForDisplay } from "../../core/init";
import { getCurrentTuning } from "../../core/fretboard";

const initialState = {
  userControlsEnabled: false,
  inProgress: false,
  answers: [],
  finishedRounds: [],
  userMessage: {
    text: "Click Start button to start the exercise",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DISPLAYED_NOTES:
      return {
        ...state,
        currentNote: action.notes,
      };

    case actionTypes.START_RUN:
      const note = action.notesToPlay.pop();

      // we enhance the FretNote and it becomes a FretNoteUnderTest
      let fretNote = {
        ...note,
        state: states.UNDER_TEST,
        startedAt: new Date().getTime(),
      };

      // TODO: Is it okay to play sound here?
      fretNote.play();

      return {
        ...state,
        ...action.options,
        userControlsEnabled: true,
        inProgress: true,
        notesToPlay: action.notesToPlay,
        currentNote: fretNote,
        answers: [],
        userMessage: {
          ...state.userMessage,
          text: action.userMessage.text,
          type: null
        },
      };

    case actionTypes.UPDATE_OPTIONS:
      const { startFret, endFret, scaleDirection } = action.options;
      const notes = setupActiveNotesForDisplay(
        startFret,
        endFret,
        scaleDirection
      );

      return {
        ...state,
        currentNote: notes,
      };

    case actionTypes.NOTE_CHECK_IN_PROGRESS:
      return {
        ...state,
        userControlsEnabled: false,
      };

    case actionTypes.STOP_TIMING_FOR_CURRENT_NOTE:
      return {
        ...state,
        currentNote: {
          ...state.currentNote,
          duration: action.duration,
        },
      };

    case actionTypes.USER_GUESSED_RIGHT:
      const correctNote = {
        ...state.currentNote,
        state: states.CORRECT_GUESS,
      };

      return {
        ...state,
        answers: [...state.answers, correctNote],
        currentNote: correctNote,
        userMessage: {
          ...state.userMessage,
          text: "Correct!",
          type: "success"
        },
      };

    case actionTypes.USER_GUESSED_WRONG:
      const wrongNote = {
        ...state.currentNote,
        state: states.WRONG_GUESS,
      };

      return {
        ...state,
        answers: [...state.answers, wrongNote],
        currentNote: wrongNote,
        userMessage: {
          ...state.userMessage,
          type: "error",
          text: "Wrong!",
        },
      };

    case actionTypes.USER_GUESS_EXPIRED:
      const expiredNote = {
        ...state.currentNote,
        state: states.EXPIRED,
      };

      return {
        ...state,
        answers: [...state.answers, expiredNote],
        currentNote: expiredNote,
        userMessage: {
          ...state.userMessage,
          type: "error",
          text: "Expired!",
        },
      };

    case actionTypes.GENERATE_NOTE_REQUEST:
      const nextNote = last(state.notesToPlay);
      nextNote.play();

      const currentAttemptNo = state.answers.length + 1;
      const userMessageText =`Attempt ${currentAttemptNo}/${state.attempts}`

      return {
        ...state,
        userControlsEnabled: true,
        notesToPlay: state.notesToPlay.slice(0, state.notesToPlay.length - 1),

        //TODO: this is the same as in START_RUN
        currentNote: {
          ...nextNote,
          state: states.UNDER_TEST,
          startedAt: new Date().getTime(),
        },
        userMessage: {
          ...state.userMessage,
          text: userMessageText,
          type: null
        },
      };

    case actionTypes.FINALIZE_RUN:
      const finishedRound = new FinishedRound(state.answers, {
        tuning: getCurrentTuning().id
      });

      return {
        ...state,
        inProgress: false,
        userControlsEnabled: false,
        wrongAnswers: finishedRound.wrongAnswers,
        currentNote: finishedRound.answers,
        finishedRounds: [...state.finishedRounds, finishedRound],
        userMessage: {
          ...state.userMessage,
          text: `${finishedRound.successRatio}% Success. Hit the Start button again!`,
        },
      };

    case actionTypes.RESET_ROUND:
      return {
        ...state,
        inProgress: false,
        userControlsEnabled: false,
        currentNote: null,
      };
    default:
      return state;
  }
};

export default reducer;
