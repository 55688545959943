import { Howl } from "howler";

const soundSprite = function () {
  return new Howl({
    src: ["/media/guitar-octaves-2-6.mp3"],
    html5: true,
    sprite: {
      "0": [0, 1000], // C2
      "1": [1000, 1000],
      "2": [2000, 1000],
      "3": [3000, 1000],
      "4": [4000, 1000],
      "5": [5000, 1000],
      "6": [6000, 1000],
      "7": [7000, 1000],
      "8": [8000, 1000],
      "9": [9000, 1000],
      "10": [10000, 1000],
      "11": [11000, 1000],
      "12": [12000, 1000], // C3
      "13": [13000, 1000],
      "14": [14000, 1000],
      "15": [15000, 1000],
      "16": [16000, 1000],
      "17": [17000, 1000],
      "18": [18000, 1000],
      "19": [19000, 1000],
      "20": [20000, 1000],
      "21": [21000, 1000],
      "22": [22000, 1000],
      "23": [23000, 1000],
      "24": [24000, 1000], // C4
      "25": [25000, 1000],
      "26": [26000, 1000],
      "28": [27000, 1000],
      "29": [28000, 1000],
      "30": [29000, 1000],
      "31": [30000, 1000],
      "32": [31000, 1000],
      "33": [32000, 1000],
      "34": [33000, 1000],
      "35": [34000, 1000],
      "36": [35000, 1000], // C5
      "37": [36000, 1000],
      "38": [37000, 1000],
      "39": [38000, 1000],
      "40": [39000, 1000],
      "41": [40000, 1000],
      "42": [41000, 1000],
      "43": [42000, 1000],
      "44": [43000, 1000],
      "45": [44000, 1000],
      "46": [45000, 1000],
      "47": [46000, 1000],
      "48": [47000, 1000], // C6
      "49": [48000, 1000],
      "50": [49000, 1000],
      "51": [50000, 1000],
      "52": [51000, 1000],
      "53": [52000, 1000],
      "54": [53000, 1000],
      "55": [54000, 1000],
      "56": [55000, 1000],
      "57": [56000, 1000],
      "58": [57000, 1000],
      "59": [58000, 1000], // B6
    },
    preload: true,
  });
}
export default soundSprite;
