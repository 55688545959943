import { notesPerString } from "./guitarTunings";
import { standardTuning } from "./namedTunings";
import { FretNote } from "./fretNote";
import FretboardString from "./fretboardString";
import Fret from "./fret";

/**
 * @typedef {Object} Fretboard
 * @property {FretNote[]} fretBoardNotes
 */

/**
 *
 * @param {Tuning} tuning
 * @returns {Fretboard}
 * @constructor
 */
function Fretboard(tuning) {
  this.tuning = tuning;
  // the juice: populating the Fretboard with FretNotes
  this.fretBoardNotes = [];
  tuning.strings.forEach((tunedString) => {
    tunedString.notes.forEach((octaveNote, fretId) => {
      this.fretBoardNotes.push(new FretNote(octaveNote, fretId, tunedString.id));
    });
  });
}

Fretboard.prototype.fret = function (fretId) {
  const notes = this.fretBoardNotes
    .filter((fretNote) => fretNote.fret === fretId)
    .sort((a, b) => (a.string > b.string ? 1 : -1));

  return new Fret(fretId, notes);
};

/**
 * @param {number} stringId
 * @returns {FretboardString}
 */
Fretboard.prototype.string = function (stringId) {
  const notes = this.fretBoardNotes
    .filter((fretNote) => fretNote.string === stringId)
    .sort((a, b) => (a.fret > b.fret ? 1 : -1));

  return new FretboardString(stringId, notes);
};

/**
 * @returns {FretboardString[]}
 */
Fretboard.prototype.strings = function () {
  const noOfStrings = this.tuning.strings.length;
  let _guitarStrings = [];
  for (let i = 1; i < noOfStrings + 1; i++) {
    _guitarStrings.push(this.string(i));
  }
  return _guitarStrings;
};

/**
 * @returns {Fret[]}
 */
Fretboard.prototype.frets = function () {
  const noOfFrets = notesPerString;
  let _frets = [];

  for (let i = 0; i < noOfFrets; i++) {
    _frets.push(this.fret(i));
  }
  return _frets;
};

// default fretboard is in standard Tuning
let fretboard = new Fretboard(standardTuning);

/**
 * Change the current fretboard to the given Tuning
 * @param {Tuning} tuning
 * @returns {Fretboard}
 */
function setCurrentTuning(tuning) {
  fretboard = new Fretboard(tuning);
}

/** @returns {Tuning} */
function getCurrentTuning() {
  return fretboard.tuning;
}

export { fretboard as default, setCurrentTuning, getCurrentTuning };
