import React from "react";
import FretNoteComp from "../FretNote/FretNoteComp";
import { connect } from "react-redux";

const Fret = (props) => {
  /** @type {FretNote} note */
  let note = props.fretNote;

  let hasMarkerPosition =
    ([3, 5, 7, 9, 15].includes(note.fret) && note.string === 4) ||
    (note.fret === 12 && [3, 5].includes(note.string));

  let fretMarkerCss = hasMarkerPosition ? 'fret-marker' : ''
  let cssStyle = `fret ${fretMarkerCss}`

  // Is this fret's note included in activeNotes?
  let activeNote = props.activeNotes
    .find(n => n.fret === note.fret && n.string === note.string)

  // if activeNote is truthy, show the FretNoteComp. Otherwise fret will be empty.
  const fretNote = !!activeNote
    ? <FretNoteComp activeNote={activeNote}/>
    : ''

  return (
    <div className={cssStyle}>
      {fretNote}
    </div>
  )
}

const mapStateToProps = (state) => {
  let activeNotes = [];

  // do we have an active run?
  if (state.run.currentNote) {
    activeNotes = Array.of(state.run.currentNote).flat();
  }

  return {
    activeNotes: activeNotes,
  };
};

export default connect(mapStateToProps)(Fret);
