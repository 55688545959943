import { takeEvery, all } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";
import { checkNote } from "./checkNote";

export function* watchNotechecking() {
  yield takeEvery(actionTypes.USER_GUESSED, checkNote);
}

export default function* rootSaga() {
  yield all([watchNotechecking()]);
}
