import { createTuning } from "./guitarTunings";

const standardTuning = createTuning(
  "6string-guitar-standard",
  "6 string guitar standard",
  [
    { octave: 4, note: "E" },
    { octave: 3, note: "B" },
    { octave: 3, note: "G" },
    { octave: 3, note: "D" },
    { octave: 2, note: "A" },
    { octave: 2, note: "E" },
  ]
);

const fourStringBassStandard = createTuning("4string-bass-standard", "Bass", [
  // {octave: 2, note: "G"},
  // {octave: 2, note: "D"},
  // {octave: 1, note: "A"},
  // {octave: 1, note: "E"},
  // FIXME: The following string set is incorrect for bass tuning.
  // We can't use the correct because the octave.js and the soundfile
  // start at octave 2. To fix this we have to add octave 1 everywhere.
  { octave: 2, note: "G" },
  { octave: 2, note: "D" },
  { octave: 2, note: "A" },
  { octave: 2, note: "E" },
]);

const threeCourseBouzoukiTuning = createTuning(
  "3-course-greek-bouzouki",
  "Greek Bouzouki (3 course)",
  [
    { octave: 4, note: "D" },
    { octave: 3, note: "A" },
    { octave: 4, note: "D" },
  ]
);

const fourCourseBouzoukiTuning = createTuning(
  "4-course-greek-bouzouki",
  "Greek Bouzouki (4 course)",
  [
    { octave: 4, note: "D" },
    { octave: 3, note: "A" },
    { octave: 3, note: "F" },
    { octave: 3, note: "C" },
  ]
);

const irishBouzoukiTuning = createTuning(
  "irish-bouzouki-standard",
  "Irish Bouzouki",
  [
    { octave: 4, note: "D" },
    { octave: 3, note: "A" },
    { octave: 3, note: "D" },
    { octave: 2, note: "G" },
  ]
);

const namedTunings = {
  standard: standardTuning,
  fourStringBassStandard: fourStringBassStandard,
  threeCourseBouzoukiTuning: threeCourseBouzoukiTuning,
  fourCourseBouzoukiTuning: fourCourseBouzoukiTuning,
  irishBouzoukiTuning: irishBouzoukiTuning,
};

/**
 * An array of objects with key/label for tunings
 * @example
 * [{'standard', 'Standard Tuning'}, {'Bass', '4 string Bass'}]
 */
const byKeyAndLabel = Object.keys(namedTunings).map((k) => ({
  key: k,
  label: namedTunings[k].name,
}));

export {
  namedTunings,
  byKeyAndLabel,
  standardTuning,
  fourStringBassStandard,
  threeCourseBouzoukiTuning,
  fourCourseBouzoukiTuning,
  irishBouzoukiTuning,
};
