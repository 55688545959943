export const INIT_APP = "INIT_APP";
export const START_RUN = "START_RUN";
export const NOTE_CHECK_IN_PROGRESS = "NOTE_CHECK_IN_PROGRESS";
export const STOP_TIMING_FOR_CURRENT_NOTE = "STOP_TIMING_FOR_CURRENT_NOTE";

export const GENERATE_NOTE_REQUEST = "GENERATE_NOTE_REQUEST";
export const FINALIZE_RUN = "FINALIZE_RUN";
export const USER_GUESSED = "USER_GUESSED";
export const USER_GUESSED_RIGHT = "USER_GUESSED_RIGHT";
export const USER_GUESSED_WRONG = "USER_GUESSED_WRONG";
export const USER_GUESS_EXPIRED = "USER_GUESS_EXPIRED";

export const SET_DISPLAYED_NOTES = "SET_DISPLAYED_NOTES";
export const RESET_ROUND = "RESET_ROUND";

export const UPDATE_OPTIONS = "UPDATE_OPTIONS";

export const SET_VERSION = "SET_VERSION";
