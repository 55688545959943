import { scaleDirection } from "../../core/octaves";
import * as actionTypes from "../actions/actionTypes";
import { saveState, loadState } from "../localStorage";

const LOCAL_STORAGE_KEY = "options";

const persistState = (state) => {
  saveState(LOCAL_STORAGE_KEY, state);
};

const updateState = (state, obj) => {
  const newState = {
    ...state,
    ...obj,
  };

  persistState(newState);
  return newState;
};

let defaultInitialState = {
  startFret: 0,
  endFret: 0,
  attempts: 5,
  tuning: "standard",
  scaleDirection: scaleDirection.ascending, // used for showing sharps/flats
  guessTimeout: 0, // time limit for user guesses
};

// restore from localStorage and merge with defaults
// TODO: we have to check that restored values are compatible
// with current fretboard configuration. (does this make sense?)
let restoredState = loadState(LOCAL_STORAGE_KEY);
if (restoredState) {
  try {
    Object.assign(defaultInitialState, restoredState);
  } catch (err) {}
}

const reducer = (state = defaultInitialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_OPTIONS:
      return updateState(state, action.options);
    default:
      return state;
  }
};

export default reducer;
