import * as actionTypes from "./actionTypes";
import { getRandomFretNotes, allStrings } from "../../core/randomFretNoteSelector";
import fretboard, { setCurrentTuning } from "../../core/fretboard";
import { namedTunings } from "../../core/namedTunings";
import { isEmpty, last } from "lodash";
import { setupActiveNotesForDisplay } from "../../core/init";

export function initApp(options) {
  let tuning = namedTunings[options.tuning];
  if (!tuning) tuning = namedTunings.standard;
  setCurrentTuning(tuning);

  return {
    type: actionTypes.INIT_APP,
    options: options,
  };
}

export function updateOptions(options) {
  setCurrentTuning(namedTunings[options.tuning]);

  return {
    type: actionTypes.UPDATE_OPTIONS,
    options: options,
  };
}

export function startRun(options, currentRun) {
  let notesToPlay = [];

  // do we have leftovers from previous run?
  // Because we save only selected attributes from an answer,
  // we can't just get it from the 'finished' array.
  // We get only fret/string values and get it afresh from object fretboard.
  //TODO: there is a bug here. If user changed fret range we should
  // check that wrong answers are withing new range before currying
  // them over to new round.

  // TODO: See this answer.string - 1 ugliness?
  // This comes from fretboard, where the strings array starts from zero
  // and when we create the note we do +1 on the string.
  // Now the question is: why do we have to do a -1 here to make it work?
  if (!isEmpty(currentRun.finishedRounds)) {
    notesToPlay = last(currentRun.finishedRounds).wrongAndExpiredAnswers.map((answer) =>
      fretboard.fret(answer.fret).string(answer.string)
    );
  }

  // fill the the rest of the array with random notes
  let randomNotes = getRandomFretNotes(
    options.attempts - notesToPlay.length,
    options.startFret,
    options.endFret,
    allStrings(),
    options.scaleDirection
  );

  notesToPlay.push(...randomNotes);

  const userMessage =`Attempt 1/${options.attempts}`
  return {
    type: actionTypes.START_RUN,
    options: options,
    notesToPlay: notesToPlay,
    userMessage: {
      text: userMessage
    }
  };
}

export function setDisplayedNotesFromOptions(options) {
  const { startFret, endFret, scaleDirection } = options;
  const notes = setupActiveNotesForDisplay(startFret, endFret, scaleDirection);
  return {
    type: actionTypes.SET_DISPLAYED_NOTES,
    notes: notes,
  };
}

export function resetRun() {
  return {
    type: actionTypes.RESET_ROUND,
  };
}
export function userGuessed(currentRun, guessedNote) {
  return {
    type: actionTypes.USER_GUESSED,
    currentRun: currentRun,
    guessedNote: guessedNote,
  };
}

export function userGuessedRight(currentRun, guessedNote) {
  const currentAttemptNo = currentRun.answers.length + 1;
  const userMessageText =`Attempt ${currentAttemptNo}/${currentRun.attempts}`

  return {
    type: actionTypes.USER_GUESSED_RIGHT,
    currentRun: currentRun,
    guessedNote: guessedNote,
    userMessage: {
      text: userMessageText
    }
  };
}

export function userGuessedWrong(currentRun, guessedNote) {
  const currentAttemptNo = currentRun.answers.length + 1;
  const userMessageText =`Attempt ${currentAttemptNo}/${currentRun.attempts}`

  return {
    type: actionTypes.USER_GUESSED_WRONG,
    currentRun: currentRun,
    guessedNote: guessedNote,
    userMessage: {
      text: userMessageText
    }
  };
}

export function userGuessExpired(currentRun, guessedNote) {
  return {
    type: actionTypes.USER_GUESS_EXPIRED,
    currentRun: currentRun,
    guessedNote: guessedNote,
  };
}

export function generateNoteRequest() {
  return {
    type: actionTypes.GENERATE_NOTE_REQUEST,
  };
}

export function noteCheckInProgress() {
  return {
    type: actionTypes.NOTE_CHECK_IN_PROGRESS,
  };
}

export function stopTimerForCurrentNote(currentRun, duration) {
  return {
    type: actionTypes.STOP_TIMING_FOR_CURRENT_NOTE,
    currentRun: currentRun,
    duration: duration,
  };
}

export function finalizeRound(currentRun) {
  return {
    type: actionTypes.FINALIZE_RUN,
    currentRun: currentRun,
  };
}
