/**
 * @param id
 * @param {FretNote[]} fretNotes
 * @constructor
 */
function FretboardString(id, fretNotes) {
  this.id = id;
  this.notes = fretNotes;
}

/**
 * Returns the note for the given fret.
 * @param fretId
 * @returns {FretNote}
 */
FretboardString.prototype.fret = function (fretId) {
  return this.notes.find((note) => note.fret === fretId && note.string === this.id);
};

export default FretboardString;
