// every time it gets called it alternates between "Ascending/Descending"

/**
 * It cycles values like a boss.
 * @param {[Object]} values - array of anything to be cycled.
 * */
export function cycler(values) {
  // TODO: throw if values is not iterable
  const arr = values;
  let index = 0;

  return function () {
    const retVal = arr[index];
    index === arr.length - 1 ? (index = 0) : (index += 1);
    return retVal;
  };
}

/**
 * Compares if two notes are the same (and in the same octave)
 * @param {FretNote} note1
 * @param {FretNote} note2
 */
export function isSameFretNote(note1, note2) {
  return note1.fret === note2.fret && note1.string === note2.string;
}
