import { put, putResolve } from "redux-saga/effects";
import * as actions from "../actions/actions";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

export function* checkNote(action) {
  yield putResolve(actions.noteCheckInProgress());

  // set the time taken for user to guess
  const duration = new Date().getTime() - action.currentRun.currentNote.startedAt;
  yield putResolve(actions.stopTimerForCurrentNote(action.currentRun, duration));
  const guessTimeoutSetting = action.currentRun.guessTimeout;

  // answer was wrong
  if (action.currentRun.currentNote.label !== action.guessedNote.label) {
    yield putResolve(actions.userGuessedWrong(action.currentRun, action.guessedNote));
  }
  // answer was given late
  else if (action.currentRun.guessTimeout > 0 && duration > guessTimeoutSetting) {
    yield putResolve(actions.userGuessExpired(action.currentRun, action.guessedNote));
  }
  // answer was in time and correct
  else if (action.currentRun.currentNote.label === action.guessedNote.label) {
    yield putResolve(actions.userGuessedRight(action.currentRun, action.guessedNote));
  }

  yield delay(350);

  // should we end the round or go for next note?
  if (action.currentRun.notesToPlay.length > 0) {
    yield put(actions.generateNoteRequest());
  } else {
    yield put(actions.finalizeRound(action.currentRun));
  }
}
