import { states } from "./noteStates";

/**
 * @typedef {Object} FinishedRound
 */

/**
 * @param {{tuning: string}} settings
 */
export default class FinishedRound {
  constructor(answers, settings = {}) {
    // keep only the necessary info.
    // fret and string are enough to retrieve all note details
    // as long as the guitar tuning is the same!
    this._answers = answers.map((a) => ({
      label: a.label,
      octave: a.octave,
      fret: a.fret,
      string: a.string,
      state: a.state,
      duration: a.duration,
    }));
    this._settings = settings;
    this._finishedAt = Date.now();
  }

  get answers() {
    return this._answers;
  }

  get wrongAnswers() {
    return this._answers.filter((a) => a.state === states.WRONG_GUESS);
  }

  get correctAnswers() {
    return this._answers.filter((a) => a.state === states.CORRECT_GUESS);
  }

  get expiredAnswers() {
    return this._answers.filter((a) => a.state === states.EXPIRED);
  }

  get wrongAndExpiredAnswers() {
    return [...this.wrongAnswers, ...this.expiredAnswers];
  }

  get successRatio() {
    return (this.correctAnswers.length / this.answers.length) * 100;
  }

  get tuning() {
    return this._settings.tuning;
  }

  get finishedAt() {
    return this._finishedAt;
  }
}
