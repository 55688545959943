import React from 'react';
import "./styles.css";
import String from "./String/String";
import fretboard from "../../core/fretboard";

const Fretboard = () => {
  const strings = fretboard.strings().map((guitarString) =>
    <String
      guitarString={guitarString}
      key={`string-${guitarString.id}`}
    />)

  return (
    <div className="fretboard">
      {strings}
    </div>
  )
}

export default Fretboard;