import { combineReducers } from "redux";
import version from "./version";
import options from "./options";
import roundStarter from "./currentRun";

const rootReducer = combineReducers({
  version: version,
  options: options,
  run: roundStarter,
});

export default rootReducer;
