/**
 * A Fret is just an array of FretNotes
 * Each member of the array represents a guitar String.
 * @typedef {Object} Fret
 * @property {function} notes
 * @function
 * @id {number} id
 */

/**
 * @param id {number} - the fret number
 * @param fretNotes {FretNote[]}
 * @returns {Fret}
 * @constructor
 */
function Fret(id, fretNotes) {
  this.id = id;
  this.notes = fretNotes;
}

/**
 * Returns a FretboardString from the given string no
 * @param {number} strNo - the guitar string
 * @returns {FretNote}
 */
Fret.prototype.string = function (strNo) {
  return this.notes.find((note) => note.string === strNo);
};

export default Fret;
