import React from "react";
import "./styles.css";

const FinishRoundButton = (props) => {
  return (
    <button className="finish-round" onClick={props.click}>
      end round
    </button>
  );
};

export default FinishRoundButton;
