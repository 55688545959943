import React from "react";
import "../styles.css";
import {connect} from "react-redux";

const CurrentAttemptIndicator = function (props) {

  const css = props.currentRun.userMessage &&
    props.currentRun.userMessage.text &&
    props.currentRun.userMessage.type
    ? props.currentRun.userMessage.type
    : ''

  return (
    <span className={css}>
      {props.currentRun.userMessage.text}
    </span>
  );
};

const mapStateToProps = function (state) {
  return {
    currentRun: state.run,
  };
};

export default connect(mapStateToProps)(CurrentAttemptIndicator);
