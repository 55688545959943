const scaleDirection = {
  ascending: "Ascending",
  descending: "Descending",
  both: "Both",
};

/**
 * Creates an object that represents a note.
 * Only the label of the note is included. No info about which octave the note is in,
 * therefore no info about its frequency.
 * @param natural {string|null}
 * @param flat {string|null}
 * @param sharp {string|null}
 * @returns {string|{readonly label: (obj.natural|string)}|*}
 */
function Note(natural, flat, sharp) {
  let obj = {
    get label() {
      if (!this._natural && !this._flat && !this._sharp) {
        throw new Error("WTF");
      }
      // default for accidental is sharp
      return this._natural || this._sharp;
    },

    get flat() {
      return this._flat;
    },

    get sharp() {
      return this._sharp;
    },

    // This is a base note, does not belong to an octave,
    // therefore equality is based only on the name.
    isEqual: function (noteName) {
      return this._natural === noteName || this._flat === noteName || this._sharp === noteName;
    },

    get isNatural() {
      return !!this._natural;
    },

    get isAccidental() {
      return !this.isNatural;
    },
  };

  natural && (obj._natural = natural);
  flat && (obj._flat = flat);
  sharp && (obj._sharp = sharp);

  return obj;
}

/**
 * Chromatic scale for key C
 */
const chromaticScale = [
  Note("C", null, null),
  Note(null, "Db", "C#"),
  Note("D", null, null),
  Note(null, "Eb", "D#"),
  Note("E", null, null),
  Note("F", null, null),
  Note(null, "Gb", "F#"),
  Note("G", null, null),
  Note(null, "Ab", "G#"),
  Note("A", null, null),
  Note(null, "Bb", "A#"),
  Note("B", null, null),
];

/**
 * Creates an array of notes, starting from octave 2 and expanding 7 octaves.
 * @returns {[]} - an array of Notes
 */
function createNotesArray() {
  const noOfOctaves = 7;
  // the frequency multiplier for each sequenced note
  const multiplier = parseFloat(
    "1.0594630943592952645618252949463417007792043174941856"
  ).toPrecision(12);
  let startFreq = 16.351;

  let notes = [];
  for (let octaveIndex = 2, noteLabelIndex = 0; octaveIndex < noOfOctaves; octaveIndex++) {
    for (let j = 0; j < chromaticScale.length; j++) {
      let currFreq = octaveIndex === 0 && j === 0 ? startFreq : startFreq * multiplier;

      const noteIndexInOctave = getNoteIndex(chromaticScale[noteLabelIndex].label);
      const notePositionInSoundfile = getSoundPositionOfNoteInSoundfile(
        octaveIndex,
        noteIndexInOctave
      );

      // TODO: this should become a full fledged stand alone, properly documented, object.
      // this is an OctaveNote object: a note within an Octave.
      /**
       * @typedef {Object} OctaveNote
       * @property {string} label - Note label. Comes from BaseNote object
       * @property {number} octave - The octave
       * @property {number} positionInOctave - Index in octave
       * @property {number} positionInSoundFind - Index in sound file
       * @property {number} freq - Note's frequency
       */
      let octaveNote = {
        ...chromaticScale[noteLabelIndex],
        octave: octaveIndex,
        positionInOctave: noteIndexInOctave, //TODO: maybe we should normalize this to start from 1
        positionInSoundFile: notePositionInSoundfile,
        freq: currFreq,
      };

      notes.push(octaveNote);

      noteLabelIndex += 1;
      startFreq = currFreq;

      // are we at the end of the octave?
      if (noteLabelIndex === chromaticScale.length) {
        noteLabelIndex = 0;
      }
    }
  }

  return notes;
}

// an array of notes starting from octave 2 and expanding 7 octaves.
const notes = createNotesArray();

/**
 * returns the position of a note within the chromatic scale of C.
 * Eg C is at 0, B is at 11
 * @param note
 * @returns {number}
 * @example
 * // returns 5
 * noteIndex("E")
 */
function getNoteIndex(note) {
  return chromaticScale.findIndex((n) => n.label === note);
}

/**
 * Given a octave and a note index, return the position in
 * the sound file for that note.
 * Note: The current sound file starts from octave 2.
 * @param octave {Number} - Which octave to get the note from
 * @param noteIndex {Number} - Index of the note
 * @returns {Number} - The position of the note in the file (in 1000ms increments)kj:w
 */
function getSoundPositionOfNoteInSoundfile(octave, noteIndex) {
  if (octave < 2 || octave > 6) {
    throw new Error("supported octaves 2 to 6 only");
  }

  let start = octave - 2; // soundfile starts at octave 2
  let octaveStart = start * 12; // 12 notes per octave
  return octaveStart + noteIndex;
}

/**
 * @returns {OctaveNote[]}
 * @param octave {Number} - from which octave to start picking notes
 * @param startNote {string} - first note in the octave
 * @param count {Number} - how many notes to pick
 * @example
 * // returns 3 notes from 2nd octave starting from D# => [D#,E,F]
 * getNotes(2, 'D#', 3)
 */
function getOctaveNotes(octave, startNote, count) {
  let startNoteIdx = notes.findIndex((note) => {
    return note.octave === octave && note.isEqual(startNote);
  });

  return notes.slice(startNoteIdx, startNoteIdx + count);
}

export {
  getOctaveNotes,
  getSoundPositionOfNoteInSoundfile,
  getNoteIndex,
  chromaticScale,
  scaleDirection,
};
