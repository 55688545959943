import React from 'react';
import {states} from "../../../core/noteStates";
import "../styles.css"
import {scaleDirection} from "../../../core/octaves";

const FretNoteComp = ({activeNote}) => {
  let cssArr = [
    'fret-note',
    `${activeNote.state}`
  ]

  let noteName = activeNote.label

  if(activeNote.isAccidental) {
    switch (activeNote.scaleDirection) {
      case scaleDirection.ascending:
        noteName = activeNote.sharp;
        break;
      case scaleDirection.descending:
        noteName = activeNote.flat;
        break;
      case scaleDirection.both:
        noteName = `${activeNote.sharp}/${activeNote.flat}`
        cssArr = [...cssArr, "dual-labels"]
        break;
      default:
    }
  }

  switch (activeNote.state) {
    case states.UNDER_TEST:
      noteName = '?'
      break;
    default:
  }

  return (
    <div
      className={cssArr.join(" ")}>
      <span>{noteName}</span>
    </div>
  )
}

export default FretNoteComp;
