import React from "react";
import {connect} from 'react-redux'
import * as actionTypes from "../../store/actions/actionTypes";

class Version extends React.Component {
  render() {
    return <div onClick={this.props.onSomethingHappened} className="version">{this.props.label}</div>
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSomethingHappened: () => dispatch({
      type: actionTypes.SET_VERSION,
      version: '3.0'
    })
  }
}

const mapStateToProps = state => {
  return {
    label: state.version.label
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Version);