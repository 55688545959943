import { getOctaveNotes } from "./octaves";

const notesPerString = 15;

/**
 * @typedef {Object} TunedString
 * @property {OctaveNote[]} notes
 */

/**
 * Creates a TunedString, an object that represents a string tuned
 * with a specific tune configuration.
 * @param {number} octave - 2 to 7
 * @param {string} startNote - "C", "D#" etc
 * @param {number } noOfNotes - how many frets will our guitar have?
 * @param {number} id - used for ordering
 * @returns {TunedString}
 * @constructor
 */
function TunedString(octave, startNote, id) {
  this.id = id;
  this.notes = getOctaveNotes(octave, startNote, notesPerString);
}

/**
 * @typedef {Object} Tuning
 * @property {string} id - the unique identifier for this tuning
 * @property {string} name
 * @property {TunedString[]} strings - Note: starts at 1
 * @property {function} string
 */

/**
 * A collection of tuned strings.
 * Used to implement different tunings, like 'standard', 'drop E' etc.
 * @param {string} id - the unique identifier for this tuning.
 * @param {string} name - the name for this tuning
 * @param {TunedString[]} tunedStrings - The tuned string that make this tuning
 * @constructor
 */
function Tuning(id, name, tunedStrings) {
  const _strings = tunedStrings;
  const _id = id;
  return {
    get strings() {
      return _strings;
    },
    get id() {
      return _id;
    },
    name: name,
    string: function (stringId) {
      return _strings[stringId - 1]; // We normalize as we want to refer to strings with 1,2,3.. not 0,1,2
    },
  };
}

/**
 * @param {string} id - The unique identifier for this tuning
 * @param {string} name - Name of the new Tuning
 * @param {Object[]} config
 * @param {number} config.octave
 * @param {string} config.note
 * @returns {Tuning}
 */
function createTuning(id, name, config) {
  let tunedStrings = config.map(
    (c, idx) => new TunedString(c.octave, c.note, idx + 1)
  );
  return new Tuning(id, name, tunedStrings);
}

export { createTuning, notesPerString };
