import soundSprite from "./soundSprite";
import { standardTuning } from "./namedTunings";

/**
 * @typedef {Object} FretNote
 * @property {string} label
 * @property {number} octave
 * @property {number} positionInOctave
 * @property {number} string
 * @property {number} fret
 * @property {number} freq
 * @property {function} play
 */

/**
 * @param octaveNote {OctaveNote}
 * @param fretId {number}
 * @param stringId {number} - the string number
 * @returns {FretNote}
 * @constructor
 */
const FretNote = function (octaveNote, fretId, stringId) {
  return {
    ...octaveNote,
    fret: fretId,
    string: stringId,
    play: function () {
      soundSprite().play(
        standardTuning.string(stringId).notes[fretId].positionInSoundFile.toString(10)
      );
    },
  };
};
export { FretNote };
